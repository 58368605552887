<template lang="pug">
	.widget
		el-card.box-card
			el-row(slot="header")
				el-col.text-left(:span="24")
					.d-flex.justify-content-between
						h3
							b Funnel Family - {{this.type}} {{type === 'Create' ? 'new' : '' }} entity
			el-form(:form="form" )
				el-row(:gutter="12")

					el-col(:xs="24", :sm="12" :lg="6" :xl="4")
						el-form-item(label="Name" v-model="form.name" :error="errors.name.s ? errors.name.m : ''" v-bind:class="{'is-invalid': errors.name.s}")
							el-input(v-model="form.name" placeholder="Name")

					el-col(:xs="24", :sm="12" :lg="6" :xl="4")
						el-form-item(label="Real Name" v-model="form.real_funnel_name" :error="errors.real_funnel_name.s ? errors.real_funnel_name.m : ''" v-bind:class="{'is-invalid': errors.real_funnel_name.s}")
							el-input(v-model="form.real_funnel_name" placeholder="Real Name")

					el-col(:xs="24", :sm="12" :lg="6" :xl="4")
						el-form-item(label="Real Name Transform")
							el-select.w-100(v-model="form.real_funnel_name_transform" placeholder="Transformation Type")
								el-option(key="NONE" value="NONE" label="Leave Current")
								el-option(key="UPPER" value="UPPER" label="To Upper Case")
								el-option(key="LOWER" value="LOWER" label="To Lower Case")
								el-option(key="CAPITAL" value="CAPITAL" label="To Capital Case")

					el-col(:xs="24", :sm="12" :lg="6" :xl="4")
						el-form-item(label="Niche")
							el-select.w-100(v-model="form.niche_id" placeholder="Select a niche")
								el-option(v-for="item in options.niches" :key="item.v" :label="item.t" :value="item.v")

					el-col(:xs="24", :sm="24" :lg="18" :xl="8")
						el-form-item(label="Real Funnel URL" v-model="form.real_funnel_url" :error="errors.real_funnel_url.s ? errors.real_funnel_url.m : ''" v-bind:class="{'is-invalid': errors.real_funnel_url.s}")
							el-input(v-model="form.real_funnel_url" placeholder="E.g https://www.example.com")

					el-col(:span="24")
						el-form-item(label="Comments" v-model="form.comments" :error="errors.comments.s ? errors.comments.m : ''" v-bind:class="{'is-invalid': errors.comments.s}")
							el-input(type="textarea" :rows="4" v-model="form.comments" placeholder="Name" show-word-limit="" maxlength="4096")


				el-row(:gutter="12")
					el-col(:xs="24" :xl="12")
						el-form-item(label="Funnels List" label-position="top" label-width="auto" :error="errors.list.s ? errors.list.m : ''" v-bind:class="{'is-invalid': errors.list.s}")
							br
						el-transfer.text-left.lead-box( filterable="" :data="options.list" v-model="form.list" :titles="['Available','Target']")

					el-col(:xs="24" :xl="12")
						el-form-item(label="Spreadsheet List" label-position="top" label-width="auto" :error="errors.spreadsheets.s ? errors.spreadsheets.m : ''" v-bind:class="{'is-invalid': errors.spreadsheets.s}")
							br
						el-transfer.text-left.lead-box( filterable="" :data="options.spreadsheets" v-model="form.spreadsheets" :titles="['Available','Target']")
				el-divider
				el-row.text-left(:gutter="12")
					h4 Bulk Actions - Override Settings
					p
						i.text-danger Note: Changing any of the settings below will affect all the funnels that are related to this funnel family.
						el-checkbox.ml-4(v-model="form.show_override_settings" :label="form.show_override_settings ? 'CLICK TO HIDE' : 'CLICK TO SHOW'" )
				el-row.mt-3(:gutter="12" v-if="form.show_override_settings")
					//el-col(:xs="24", :sm="4")
						el-form-item(label="Redirect Params" label-position="top")
							el-radio-group.w-100(v-model="form.override.allow_redirect_params_reg" :fill="form.override.allow_redirect_params_reg === true ? '#409EFF' : form.override.allow_redirect_params_reg === -1 ? '#c9c9c9' : 'red'")
								el-radio-button(:label="true") On
								el-radio-button(:label="false") Off
								el-radio-button(:label="-1") Leave
					el-col(:xs="24", :sm="4")
						el-form-item(label="SMS" label-position="top")
							el-radio-group.w-100(v-model="form.override.sms" :fill="form.override.sms === true ? '#409EFF' : form.override.sms === -1 ? '#c9c9c9' : 'red'")
								el-radio-button(:label="true") On
								el-radio-button(:label="false") Off
								el-radio-button(:label="-1") Leave
					el-col(:xs="24", :sm="4")
						el-form-item(label="Password Include" label-position="top")
							el-radio-group.w-100(v-model="form.override.password" :fill="form.override.password === true ? '#409EFF' : form.override.password === -1 ? '#c9c9c9' : 'red'")
								el-radio-button(:label="true") On
								el-radio-button(:label="false") Off
								el-radio-button(:label="-1") Leave
					el-col(:xs="24", :sm="4")
						el-form-item(label="Allow Duplicates" label-position="top")
							el-radio-group.w-100(v-model="form.override.allow_duplicates" :fill="form.override.allow_duplicates === true ? '#409EFF' : form.override.allow_duplicates === -1 ? '#c9c9c9' : 'red'")
								el-radio-button(:label="true") On
								el-radio-button(:label="false") Off
								el-radio-button(:label="-1") Leave
					el-col(:xs="24", :sm="4")
						el-form-item(label="Is Strict Phone Number" label-position="top")
							el-radio-group.w-100(v-model="form.override.is_strict_phone" :fill="form.override.is_strict_phone === true ? '#409EFF' : form.override.is_strict_phone === -1 ? '#c9c9c9' : 'red'")
								el-radio-button(:label="true") On
								el-radio-button(:label="false") Off
								el-radio-button(:label="-1") Leave
					el-col(:xs="24", :sm="4")
				div(v-if="form.show_override_settings")
					h5.text-left
						b Redirect Tracking Params
					p.text-left Add or remove params that you want to be redirected between the pages. For example&nbsp;
						span.text-success.text-bold gclid,&nbsp;
						span.text-success.text-bold fbcid,&nbsp;
						span.text-success.text-bold vkclid,&nbsp;
						span.text-success.text-bold yclid,&nbsp;
						| etc. This param, will be concatenated in the URI in case it exists.&nbsp;
						span(style="font-style: underline") Relevant to the 2nd step.
					p.text-left.text-danger
						i Note: To clear the tracking params of all funnels of this funnel family type:&nbsp;
						el-tag(type="warning" effect="plain") --clear

				el-row(:gutter="12"  v-if="form.show_override_settings")
					el-col(:xs="24" size="mini")
						el-form-item(label="Tracking params: " label-position="top")
							.w-100.text-left.d-block
								el-tag.mr-1(:key='tag', v-for='tag in form.override.params_to_redirect', closable='', :disable-transitions='false', @close='handleClosePTR(tag)')
									| {{tag}}
								el-input.input-new-tag(v-if='inputVisiblePTR', v-model='inputValuePTR', ref='saveTagInputPTR', size='mini', @keyup.enter.native='handleInputConfirmPTR', @blur='handleInputConfirmPTR')
								el-button.button-new-tag.ml-2(v-else='', size='small', @click='showInputPTR') + New Tag
								el-button.button-new-tag(size='small', type="primary" @click="populateDefaultPTR") Lead Details
								el-button.button-new-tag(size='small', type="danger" @click="clearPTR") Clear All
			el-divider
			el-row(:gutter="12" justify="between")
				el-button(@click="submit(true)" type="primary") Save
				el-button(type="danger" @click="$router.push({name: 'iframe-list'})") Cancel
</template>

<style lang="scss">
.lead-box {
	.el-transfer-panel {
		width: 300px;
	}
}
.input-new-tag {
	width: 120px;
	margin-left: 10px;
	vertical-align: bottom;
}
</style>


<script lang="js">
import Vue from "vue";
import validator from "validator";
import {validateUrlOrTemplate} from "@/utils/validate";

const $form = {
	name: '',
	real_funnel_name_transform: 'NONE',
	real_funnel_name: '',
	real_funnel_url: '',
	niche_id: '',
	list: [],
	spreadsheets: [],
	show_override_settings: false,
	comments: '',
	override: {
		// allow_redirect_params_reg: -1,
		sms: -1,
		password: -1,
		allow_duplicates: -1,
		is_strict_phone: -1,
    params_to_redirect: []
	},
	options: {
		niches: [],
		list: [],
		spreadsheets: []
	}
};
const $error = {
	name:	{ m: '', s: false },
	real_funnel_name:	{ m: '', s: false },
	real_funnel_url:	{ m: '', s: false },
	comments:	{ m: '', s: false },
	list: { m: '', s: false },
	spreadsheets: { m: '', s: false }
};
export default {
	async beforeRouteEnter(to, from, next) {
		if (to.name !== 'iframe-add-funnel-family' && to.name !== 'iframe-edit-funnel-family') return next();
		if (to.name === 'iframe-add-funnel-family') {
			const options = await Vue.apix.sendHttpRequest('GET', 'components/options', { filters: '1ef2b5d2-7c11-4369-a1c8-59e74e01c252,ae78b931-9fe2-4519-b6ca-5ad706329c90,1e0c327a-73cf-4159-b154-ec498ade6dcd' });
			next(vm => {
				vm.options = {};
				vm.options.list = options.funnel_extra.map(e => Object.assign({}, { key: e.v, label: `${e.t} (${e.n})` }))
				vm.options.spreadsheets = options.spreadsheets.map(e => Object.assign({}, { key: e.v, label: `${e.bn} (${e.t})` }))
				vm.options.niches = options.niches;
				vm.form = JSON.parse(JSON.stringify($form));
				vm.errors = JSON.parse(JSON.stringify($error))
				vm.type = 'Create';
			});
		} else if (to.name === 'iframe-edit-funnel-family') {
			const options = await Vue.apix.sendHttpRequest('GET', 'components/options', { filters: '1ef2b5d2-7c11-4369-a1c8-59e74e01c252,ae78b931-9fe2-4519-b6ca-5ad706329c90,1e0c327a-73cf-4159-b154-ec498ade6dcd' });
			const result = await Vue.apix.sendHttpRequest('GET', 'funnel-family/' + to.params.id)
			next(vm => {
				vm.options = {};
				vm.options.list = options.funnel_extra.map(e => Object.assign({}, { key: e.v, label: `${e.t} (${e.n})` }))
				vm.options.spreadsheets = options.spreadsheets.map(e => Object.assign({}, { key: e.v, label: `${e.bn} (${e.t})` }))
				vm.options.niches = options.niches;
				vm.form = JSON.parse(JSON.stringify($form));
				vm.form.name = result.name;
				vm.form.real_funnel_name_transform = result.real_funnel_name_transform;
				vm.form.real_funnel_name = result.real_funnel_name;
				vm.form.real_funnel_url = result.real_funnel_url;
				vm.form.real_funnel_comments = result.real_funnel_comments;
				vm.form.niche_id = result.niche_id;
				vm.form.comments = result.comments;
				vm.form.list = result.funnel.map(e => e.id);
				vm.form.spreadsheets = result.spreadsheet.map(e => e.id);
				vm.errors = JSON.parse(JSON.stringify($error));
				vm.type = 'Edit';
			});
		}
	},
	data() {
		return {
			type: 'Create',
			inputVisiblePTR: false,
			inputValuePTR: '',
			form: JSON.parse(JSON.stringify($form)),
			options: { list: [], spreadsheets: [] },
			errors: JSON.parse(JSON.stringify($error))
		}
	},

	methods: {
		clearPTR() {
			this.form.override.params_to_redirect = [];
		},
		populateDefaultPTR() {
			const list = [...this.form.override.params_to_redirect, 'first_name','last_name','email','phone',];
			this.form.override.params_to_redirect = [... new Set(list)];
		},
    showInputPTR() {
      this.inputVisiblePTR = true;
      this.$nextTick(_ => {
        this.$refs.saveTagInputPTR.$refs.input.focus();
      });
    },
    handleClosePTR(tag) {
      this.form.override.params_to_redirect.splice(this.form.override.params_to_redirect.indexOf(tag), 1);
    },
    handleInputConfirmPTR() {
      let inputValuePTR = this.inputValuePTR;
      if(!inputValuePTR || inputValuePTR === '') {
        this.inputVisiblePTR = false;
        this.inputValuePTR = '';
      } else if (inputValuePTR && inputValuePTR.length > 1 && inputValuePTR.length < 16) {
        this.form.override.params_to_redirect.push(inputValuePTR);
        this.inputVisiblePTR = false;
        this.inputValuePTR = '';
      } else {
        this.$notify.error({title: 'Validation Error', message: 'Validation failed. Redirect param must be more then 1 character and less then 16 characters'})
      }
    },
		preparePayload() {
			const body = {};
			body.name = `${this.form.name}`;
			body.real_funnel_name_transform = this.form.real_funnel_name_transform;
			body.real_funnel_name = `${this.form.real_funnel_name}`;
			body.real_funnel_url = this.form.real_funnel_url;
			body.niche_id = this.form.niche_id;
			body.comments = this.form.comments;
			body.list = [...this.form.list];
			console.log('[preparePayload]')
			body.spreadsheets = [...this.form.spreadsheets];
			body.show_override_settings = this.form.show_override_settings;
			if (this.form.show_override_settings) {
				body.override = { };
				if (this.form.override.sms !== -1)
					body.override.sms = this.form.override.sms;
				if (this.form.override.password !== -1)
					body.override.password = this.form.override.password;
				if (this.form.override.is_strict_phone !== -1)
					body.override.is_strict_phone = this.form.override.is_strict_phone;
				if (this.form.override.allow_duplicates !== -1)
					body.override.allow_duplicates = this.form.override.allow_duplicates;
				// if (this.form.override.allow_redirect_params_reg !== -1)
				// 	body.override.allow_redirect_params_reg = this.form.override.allow_redirect_params_reg;
				if (this.form.override.params_to_redirect.length > 0)
					body.override.params_to_redirect = this.form.override.params_to_redirect;
			}
			return body;
		},
		submit() {
			const payload = this.preparePayload();
			if(!this.validate()) return this.$notify.warning({ title: 'Validation error', message: 'Please, fix the validation errors in this form to proceed. You can see the error text in red font'});
			if(this.type === 'Create') {
				let loader = this.$loading.show();
				this.$apix.sendHttpRequest('POST', 'funnel-family/', payload)
					.then(res => {
						if (res.id) this.$router.push({name: 'iframe-list'})
						loader.hide()
					})
					.catch(err => {
						this.$notify.error('There was an issue. We are fixing the bug');
						console.error(err);
						loader.hide();
					});
			} else if(this.type === 'Edit' ) {
				let loader = this.$loading.show();
				this.$apix.sendHttpRequest('PUT', 'funnel-family/' + this.$route.params.id, payload)
					.then(res => {
						if (res.id) this.$router.push({name: 'iframe-list'})
						loader.hide()
					})
					.catch(err => {
						this.$notify.error('There was an issue. We are fixing the bug');
						console.error(err);
					});
			}
		},
		validate() {
			this.errors = JSON.parse(JSON.stringify($error));

			let error = false;

			if (this.form.name === '') {
				this.errors.name = { s: true, m: 'name cannot be empty' };
				error = true;
			} else if (!validator.isAscii(this.form.name)) {
				this.errors.name = { s: true, m: 'name malformed' };
				error = true;
			}

			if (this.form.real_funnel_name === '') {
				this.errors.real_funnel_name = { s: true, m: 'Real name cannot be empty' };
				error = true;
			} else if (!validator.isAscii(this.form.real_funnel_name)) {
				this.errors.real_funnel_name = { s: true, m: 'Real name malformed' };
				error = true;
			}

			if (this.form.real_funnel_url !== '' && this.form.real_funnel_url !== null && !validateUrlOrTemplate(this.form.real_funnel_url)) {
				this.errors.real_funnel_url = { s: true, m: 'Real funnel URL malformed' };
				error = true;
			}


			if(this.form.list.length === 0) {
				this.errors.list = { s: true, m: 'funnel list cannot be empty' };
				error = true;
			}

			/*if(this.form.spreadsheets.length === 0) {
				this.errors.spreadsheets = { s: true, m: 'spreadsheet list cannot be empty' };
				error = true;
			}*/

			return !error;
		}
	}
}
</script>
